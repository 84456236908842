import { useState } from "react";

function App() {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.currentTarget.elements.email.value;
    const offer = e.currentTarget.elements.offer.value;
    const notes = e.currentTarget.elements.notes.value;

    await fetch("https://eocglnzr4of1tj9.m.pipedream.net/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        offer,
        notes,
        host: window.location.host,
      }),
    });
    setSubmitted(true);
  };

  return (
    <div class="container mx-auto">
      <div class="my-4 text-2xl font-medium text-center">Buy This Domain</div>
      <form
        action="https://eocglnzr4of1tj9.m.pipedream.net/"
        method="POST"
        target="submission"
        onSubmit={handleSubmit}
      >
        {(!submitted && (
          <div>
            <div class="my-4">
              <label class="block text-gray-700 font-medium mb-2" for="email">
                Contact Email
              </label>
              <input
                class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
                type="email"
                id="email"
                placeholder="email@example.com"
              />
            </div>
            <div class="my-4">
              <label class="block text-gray-700 font-medium mb-2" for="offer">
                Offer Value ($USD)
              </label>
              <input
                class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
                type="text"
                id="offer"
                placeholder="$0.00"
              />
            </div>
            <div class="my-4">
              <label class="block text-gray-700 font-medium mb-2" for="notes">
                Notes
              </label>
              <textarea
                class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
                id="notes"
                rows="3"
                placeholder="Add notes here..."
              ></textarea>
            </div>
            <div class="my-4 text-center">
              <button class="bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-600">
                Submit Offer
              </button>
            </div>
          </div>
        )) || <div>Submission Received. We'll be in contact</div>}
      </form>
    </div>
  );
}

// Write a component with tailwind that creates a basic form with a button to submit the form.
// The form should have an email input and a number input.
// The button should be disabled if the email is not valid or the number is not a number.
// The button should be enabled if the email is valid and the number is a number.
// The button should be styled with tailwind.

function Form() {
  return (
    <form className="flex flex-col gap-4">
      <label htmlFor="email">Email</label>
      <input type="email" className="border-2 border-black" />

      <label htmlFor="number">Number</label>
      <input type="number" />

      <button type="submit" disabled>
        Submit
      </button>
    </form>
  );
}

export default App;
